import Vue from 'vue';
import Swiper from 'swiper';
import VueRellax from 'vue-rellax';
import { WOW } from 'wowjs';
import AOS from 'aos';
import VueSocialSharing from 'vue-social-sharing';
import './deviceDetecter';
import VScrollLock from 'v-scroll-lock';

Vue.use(VScrollLock);
Vue.use(VueRellax);
Vue.use(VueSocialSharing);

import { Container, Draggable } from 'vue-smooth-dnd';

Vue.component('Container', Container);
Vue.component('Draggable', Draggable);

import '../scss/main.scss';
import 'swiper/src/swiper.scss';

const hostname = process.env.BASE_URL;

new Vue({
    el: '#app',

    data: () => ({
        swiper: null,
        random: Math.random(),
        isMount: false,

        draggableId: 0,
        packs: [
            { id: 1, text: 'пикник-пати' },
            { id: 2, text: 'гриль-пати' },
            { id: 3, text: 'тусу с настолками' },
            { id: 4, text: 'пенную вечеринку' },
            { id: 5, text: 'пижамную тусу' },
            { id: 6, text: 'квест-пати' },
            { id: 7, text: 'дискотеку' },
        ],
        gifts: [
            { id: 1, type: 'A', text: 'ламповую', share: { x: 320, y: 200, w: 216, h: 1.1111, deg: -8.5 } },
            { id: 2, type: 'A', text: 'шумную', share: { x: 270, y: 200, w: 302, h: 0.762, deg: -10 } },
            { id: 3, type: 'C', text: 'в бассейне', share: { x: 280, y: 200, w: 258, h: 0.876, deg: -8.5 } },
            { id: 4, type: 'A', text: 'пляжную', share: { x: 290, y: 200, w: 237, h: 0.89, deg: -8.5 } },
            { id: 5, type: 'A', text: 'скейтерскую', share: { x: 310, y: 200, w: 212, h: 1.075, deg: -8.5 } },
            { id: 6, type: 'C', text: 'под открытым небом', share: { x: 300, y: 200, w: 229, h: 0.978, deg: -8.5 } },
            { id: 7, type: 'C', text: 'на даче', share: { x: 300, y: 200, w: 262, h: 0.855, deg: -8.5 } },
            { id: 8, type: 'A', text: 'готичную', share: { x: 280, y: 200, w: 282, h: 0.748, deg: -8.5 } },
            { id: 9, type: 'C', text: 'с просмотром фильма на проекторе', share: { x: 260, y: 200, w: 271, h: 0.885, deg: -8.5 } },
            { id: 10, type: 'C', text: 'с видеоиграми', share: { x: 300, y: 200, w: 236, h: 0.7712, deg: -8.5 } },
            { id: 11, type: 'C', text: 'с мексиканской едой', share: { x: 300, y: 180, w: 218, h: 1.037, deg: 50 } },
            { id: 12, type: 'A', text: 'костюмированную', share: { x: 280, y: 200, w: 252, h: 0.817, deg: -8.5 } },
            { id: 13, type: 'C', text: 'в караоке', share: { x: 310, y: 160, w: 170, h: 1.453, deg: -8.5 } },
            { id: 14, type: 'A', text: 'выездную', share: { x: 310, y: 190, w: 212, h: 1.057, deg: -8.5 } },
        ],
        activeSlide: 1,
        pack1: null,
        pack2: null,
        gift1: null,
        gift2: null,
        mixStep: 1,
        errorPacks: false,
        animateChips: false,

        openModal: false,

        image: '',
        chips: [],
    }),

    computed: {
        dndPacks() {
            const x = this.isDesktop ? 2 : 1;
            const end = this.activeSlide === this.packs.length ? this.packs.length-x : this.activeSlide + x+1;
            let start = this.activeSlide === 0 ? 0 : this.activeSlide-x;

            if (this.isDesktop && this.activeSlide === 1) {
                start = 0;
            }
            const arr = this.packs.slice(start, end);
            if (this.activeSlide === 0 || (this.isDesktop && this.activeSlide === 1)) {
                arr.unshift(this.packs[this.packs.length -1]);
            }
            if (this.activeSlide === this.packs.length -1) {
                arr.push(this.packs[0]);
            }
            if (this.isDesktop) {
                if (this.activeSlide === 0) {
                    arr.unshift(this.packs[this.packs.length -2]);
                } else if (this.activeSlide === this.packs.length -2) {
                    arr.push(this.packs[0]);
                } else if (this.activeSlide === this.packs.length -1) {
                    arr.push(this.packs[1]);
                }
            }
            return arr;
        },
        dndGifts() {
            const x = this.isDesktop ? 2 : 1;
            const end = this.activeSlide === this.gifts.length ? this.gifts.length-x : this.activeSlide + x+1;
            let start = this.activeSlide === 0 ? 0 : this.activeSlide-x;
            if (this.isDesktop && this.activeSlide === 1) {
                start = 0;
            }
            const arr = this.gifts.slice(start, end);
            if (this.activeSlide === 0 || (this.isDesktop && this.activeSlide === 1)) {
                arr.unshift(this.gifts[this.gifts.length -1]);
            }
            if (this.activeSlide === this.gifts.length -1) {
                arr.push(this.gifts[0]);
            }
            if (this.isDesktop) {
                if (this.activeSlide === 0) {
                    arr.unshift(this.gifts[this.gifts.length -2]);
                } else if (this.activeSlide === this.gifts.length -2) {
                    arr.push(this.gifts[0]);
                } else if (this.activeSlide === this.gifts.length -1) {
                    arr.push(this.gifts[1]);
                }
            }
            return arr;
        },
        resultText() {
            let text = this.pack1?.text + '&nbsp;<span>&nbsp;X&nbsp;</span>&nbsp;' + this.pack2?.text;
            if (this.gift1?.type === 'A') {
                text = this.gift1?.text + ' ' + text;
            } else {
                text = ' ' + text + ' ' + this.gift1?.text;
            }
            if (this.gift2?.type === 'A') {
                text = this.gift2?.text + ' ' + text;
            } else {
                text = ' ' + text + ' ' + this.gift2?.text;
            }
            return 'и на ' + text.trim();
        },
        resultTextShare() {
            return this.resultText
                .replaceAll('&nbsp;', '')
                .replaceAll('<span>', ' ')
                .replaceAll('</span>', ' ');
        },
        url() {
            return `${hostname}/share.php?image=${this.image}&redirectUrl=${window.location.href}`;
        },
        isDesktop() {
            console.log(this.random);
            return window.innerWidth>=1001;
        },
        resImgs() {
            let packsDir = './assets/img/mix/packs';
            let giftsDir = './assets/img/mix/gifts';
            if (this.isDesktop) {
                giftsDir += '/d';
                packsDir += '/d';
            }
            return {
                pack1: `${packsDir}/${this.pack1.id}.png`,
                pack2: `${packsDir}/${this.pack2.id}.png`,
                gift1: `${giftsDir}/g${this.gift1.id}.png`,
                gift2: `${giftsDir}/g${this.gift2.id}.png`,
            };
        },
    },

    methods: {
        initPacksSwiper() {
            const that = this;
            if (this.swiper1) return;
            this.swiper1 = new Swiper('#packsSwiper', {
                spaceBetween: 10,
                slidesPerView: 4.8,
                loop: true,
                centeredSlides: true,
                initialSlide: 2,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                on: {
                    activeIndexChange() {
                        setTimeout(() => {
                            that.activeSlide = that.swiper1.realIndex;
                        });
                    },
                },
                breakpoints: {
                    1023: {
                        slidesPerView: 3,
                    },
                },
            });
        },
        initGiftsSwiper() {
            const that = this;
            if (this.swiper2) return;
            this.swiper2 = new Swiper('#giftsSwiper', {
                spaceBetween: 10,
                slidesPerView: 4.8,
                loop: true,
                centeredSlides: true,
                initialSlide: 2,
                allowTouchMove: false,
                navigation: {
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                on: {
                    activeIndexChange() {
                        setTimeout(() => {
                            that.activeSlide = that.swiper2.realIndex;
                        });
                    },
                },
                breakpoints: {
                    1023: {
                        slidesPerView: 3,
                    },
                },
            });
        },

        getPackPayload(id) {
            return this.packs.find(p => p.id === id);
        },
        getGiftsPayload(id) {
            return this.gifts.find(p => p.id === id);
        },

        onDrop(id, dropResult) {
            this.draggableId = 0;
            if (id === 1 && dropResult.addedIndex === 0) {
                this.pack1 = dropResult.payload;
            }
            if (id === 2 && dropResult.addedIndex === 0) {
                this.pack2 = dropResult.payload;
            }
            if (id === 3 && dropResult.addedIndex === 0) {
                this.gift1 = dropResult.payload;
            }
            if (id === 4 && dropResult.addedIndex === 0) {
                this.gift2 = dropResult.payload;
            }
        },

        selectItem(e) {
            if (e.target.querySelector('.disabled')) return;

            const disabled = e.target.closest('.smooth-dnd-container').getAttribute('data-disabled');
            if (disabled) return;

            const id = +e.target.closest('.smooth-dnd-container').getAttribute('data-id');
            const arr = this.mixStep === 1 ? 'pack' : 'gift';
            if (!this[`${arr}1`] || this.selectPack === 2) {
                this.selectPack = 1;
                this[`${arr}1`] = this[`${arr}s`].find(p => p.id === id);
                return;
            } else if (!this.pack2 || this.selectPack === 1) {
                this.selectPack = 2;
                this[`${arr}2`] = this[`${arr}s`].find(p => p.id === id);
                return;
            }
        },

        removeDNDClasses() {
            setTimeout(() => {
                document.querySelector('body').classList.remove('smooth-dnd-no-user-select');
                document.querySelector('body').classList.remove('smooth-dnd-disable-touch-action');
            });
        },

        onDragStart(id, dragResult) {
            if (dragResult.isSource) {
                this.draggableId = id;
            }
        },

        setErrorPacks() {

            this.errorPacks = true;
            setTimeout(() => {
                this.errorPacks = false;
            },1000);
        },

        setMixStep(step) {
            if (step===1) {
                this.animateChips = false;
                setTimeout(() => {
                    this.initPacksSwiper();
                }, 350);
            } else if (step===2) {
                if (!this.pack1 || !this.pack2) {
                    this.setErrorPacks();
                    return;
                }
                setTimeout(() => {
                    this.initGiftsSwiper();
                });
            }

            this.errorPacks = false;
            this.mixStep = step;
            this.scrollTo('.mix');
        },

        retry() {
            this.pack1 = null;
            this.pack2 = null;
            this.gift1 = null;
            this.gift2 = null;
            this.setMixStep(1);
            this.errorPacks = false;
        },

        toResult() {
            if (this.gift1 && this.gift2) {
                this.animateChips = true;
                this.drawImg();
                setTimeout(() => {
                    this.setMixStep(3);
                }, 3000);
                this.swiper1 = null;
                this.swiper2 = null;
            } else {
                this.setErrorPacks();
            }
        },

        loadImage(url) {
            return new Promise(r => { const i = new Image(); i.onload = (() => r(i)); i.src = url; });
        },
        async drawImg() {
            const that = this;
            const canvas = document.getElementById('canvas');
            canvas.style.letterSpacing = '1px';
            let ctx = canvas.getContext('2d');
            const packW = 310;
            const gradW = 1295;
            const gradH = 833;

            ctx.fillRect(0,0,canvas.width, canvas.height);

            const resGrad = await this.loadImage(`../assets/img/mix/res-grad.png`);
            const img1 = await this.loadImage(`../assets/img/mix/packs/${this.pack1.id}.png`);
            const img2 = await this.loadImage(`../assets/img/mix/packs/${this.pack2.id}.png`);
            const img3 = await this.loadImage(`../assets/img/mix/gifts/g${this.gift1.id}.png`);
            const img4 = await this.loadImage(`../assets/img/mix/gifts/g${this.gift2.id}.png`);
            draw();

            const f = new FontFace('DearType', `url(${require(`../assets/fonts/dear-type/DearType-LifehackSansMedium.otf`)})`);
            await f.load().then((font) => {
                document.fonts.add(font);

                ctx.save();
                ctx.translate(0, 0);
                ctx.rotate(-3*Math.PI/180);
                ctx.textAlign = 'center';

                ctx.fillStyle = '#9D1C83';
                canvas.style.letterSpacing = '0px';
                ctx = canvas.getContext('2d');
                ctx.font = '62px DearType';
                if (window.isSafari) {
                    ctx.font = '60px DearType';
                }
                if (window.isAndroid) {
                    ctx.font = '58px DearType';
                }
                ctx.fillText('ГО СМЕШАЕМ ВКУСЫ', (canvas.width)/2+2, 94);

                canvas.style.letterSpacing = '2px';
                if (window.isAndroid) {
                    canvas.style.letterSpacing = '0px';
                }
                ctx = canvas.getContext('2d');

                const lines = getLines(ctx, this.resultTextShare.toUpperCase(), 300,'32px DearType');
                lines.forEach((l, index) => {
                    ctx.fillText(l, (canvas.width)/2 + 4, 125 + 4 + index*32);
                });

                ctx.fillStyle = '#000';
                canvas.style.letterSpacing = '2px';
                if (window.isAndroid) {
                    canvas.style.letterSpacing = '0px';
                }
                ctx = canvas.getContext('2d');
                ctx.font = '56px DearType';
                if (window.isSafari) {
                    ctx.font = '58px DearType';
                }
                ctx.fillText('ГО СМЕШАЕМ ВКУСЫ', (canvas.width)/2 + 2, 90 + 2);

                ctx.font = '32px DearType';
                lines.forEach((l, index) => {
                    ctx.fillText(l, (canvas.width)/2+2, 125 + 2 + index*32);
                });

                ctx.fillStyle = '#fff';
                ctx.font = '56px DearType';
                if (window.isSafari) {
                    ctx.font = '58px DearType';
                }
                ctx.fillText('ГО СМЕШАЕМ ВКУСЫ', (canvas.width)/2, 90);

                ctx.font = '32px DearType';
                lines.forEach((l, index) => {
                    ctx.fillText(l, (canvas.width)/2, 125 + index*32);
                });

                ctx.translate(-(canvas.width/2), -(0));
                ctx.restore();
            });

            this.image = hostname + await this.uploadImg(canvas.toDataURL('image/png'));

            function draw () {
                ctx.drawImage(resGrad, canvas.width/2 - gradW/2, canvas.height/2 - gradH/2, gradW, gradH);
                drawImg(img1, -13, 60, 60, packW, packW*1.262);
                drawImg(img2, 17, canvas.width - packW - 55, 60, packW, packW*1.262);
                drawImg(
                    img3,
                    that.gift1.share?.deg,
                    that.gift1.share?.x,
                    that.gift1.share?.y,
                    that.gift1.share?.w,
                    that.gift1.share?.w * that.gift1.share?.h,
                );
                drawImg(
                    img4,
                    that.gift2.share?.deg,
                    canvas.width - that.gift2.share?.w - that.gift2.share?.x,
                    that.gift2.share?.y,
                    that.gift2.share?.w,
                    that.gift2.share?.w * that.gift2.share?.h,
                );

            }
            function drawImg(img, deg, x, y, w, h) {
                ctx.save();
                ctx.translate(x+w/2, y+h/2);
                ctx.rotate(deg*Math.PI/180);
                ctx.translate(-(x+w/2), -(y+h/2));

                ctx.drawImage(img, x, y, w, h);
                ctx.restore();
            }
        },
        async uploadImg(img) {
            const res = await fetch(hostname + '/api/link/add', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    image: img,
                }),
            }).then(res=>res.json());

            return res.image;
        },
        async share() {
            try {
                setTimeout(() => {
                    document.querySelector('.share-vk').click();
                });
            } catch (e) {
                console.log(e);
            }

        },

        elemInView() {
            if (!this.chips.length) {
                this.chips = document.querySelectorAll('.checkViewChip img');
            }
            this.chips.forEach(el => {
                const bounds = el.getBoundingClientRect();
                const inView = (
                    (bounds.top + bounds.height > -200) &&
                    (window.innerHeight - bounds.top > -200)
                );
                if (!inView && !el.classList.contains('hidden')) {
                    el.closest('.checkViewChip').classList.add('hidden');
                } else {
                    el.closest('.checkViewChip').classList.remove('hidden');
                }
            });
            requestAnimationFrame(this.elemInView);
        },

        scrollTo(selector) {
            const top = document.querySelector(selector)?.getBoundingClientRect().top + window.pageYOffset - 15;
            window.scrollTo({
                top,
                behavior: 'smooth',
            });
        },
    },

    mounted() {
        setTimeout(() => {
            this.initPacksSwiper();
            this.elemInView();

            window.addEventListener('scroll', this.removeDNDClasses);
            window.addEventListener('touchend', this.removeDNDClasses);
            window.addEventListener('resize', () => {
                this.random = Math.random();
            });

            new WOW().init();
            AOS.init({
                offset: 200,
                once: true,
            });
            this.isMount = true;
        }, 1000);
        window.addEventListener('load', () => {
            /* eslint-disable */
            new LazyLoad(null, {
                rootMargin: '700px',
            });

            /* eslint-enable */
        });
    },
});

function getLines(ctx,phrase,maxPxLength,textStyle) {
    var wa = phrase.split(' '),
        phraseArray = [],
        lastPhrase = wa[0],
        measure = 0,
        splitChar = ' ';
    if (wa.length <= 1) {
        return wa;
    }
    ctx.font = textStyle;
    for (var i = 1; i < wa.length; i++) {
        var w = wa[i];
        measure = ctx.measureText(lastPhrase + splitChar + w).width;
        if (measure < maxPxLength) {
            lastPhrase += (splitChar + w);
        } else {
            phraseArray.push(lastPhrase);
            lastPhrase = w;
        }
        if (i === wa.length - 1) {
            phraseArray.push(lastPhrase);
            break;
        }
    }
    return phraseArray;
}
